<template>
  <!--begin::Farm controller-->
  <div class="card card-custom gutter-b dashboard-farm-controller">
    <!--begin::controller-tabs-->
    <div class="controller-tabs">
      <b-tabs @activate-tab="activateTab">
        <b-tab v-for="(item, i) in connects" :key="i" :active="i === idx">
          <template slot="title">
            <div class="mr-3" style="text-align: center;">
              <span class="nav-text font-weight-bolder font-size-h4">{{ item.connectNm }}</span>              
              <div style="height: 0px;">
                <span v-show="!renewalAlarmList[item.systemId]">{{i18n.renewing}}</span>
              </div>
            </div>
          </template>
        </b-tab>
        <!-- <template v-if="!isMobile" #tabs-end>
          <b-nav-item role="presentation">
              <button class="btn btn-dark col-12" @click="showMeasureModal()">
                <div class="row">
                  <div class="col-12"><span class='icon-xs fas fa-pen mr-2'></span>{{ i18n.inputMeasauredData }}</div>
                </div>
              </button>
          </b-nav-item>
        </template> -->
        <div class="card-body pt-0">
          <div class="row d-flex">
            <!-- <button v-if="isMobile" class="btn btn-dark col-12 col-lg-12 col-xxl-1 mb-5 p-2 h5 w-100" @click="showMeasureModal()"><span class='icon-xs fas fa-pen mr-2'></span>{{ i18n.inputMeasauredData }}</button> -->

            <div v-if="waterTempYn" class="col-4 col-lg-4 col-xxl-1">
              <stats-widget1 :temperature="temperature" :collectYn="tempYn"></stats-widget1>
            </div>
            <div v-if="waterTempYn" class="col-4 col-lg-4 col-xxl-1">
              <stats-widget13 :waterTemperature="waterTemperature"></stats-widget13>
            </div>
            <div v-if="waterTempYn" class="col-4 col-lg-4 col-xxl-1">
              <stats-widget14 :waterLevel="waterLevel" :renewalAlarm="renewalAlarm" :info="info" @updateRenewalAlarm="updateRenewalAlarm" :collectYn="waterLevelYn"></stats-widget14>
            </div>

            <div v-if="!waterTempYn" class="col-6 col-lg-6 col-xxl-1">
              <stats-widget1 :temperature="temperature" :collectYn="tempYn"></stats-widget1>
            </div>            
            <div v-if="!waterTempYn" class="col-6 col-lg-6 col-xxl-1">
              <stats-widget14 :waterLevel="waterLevel" :renewalAlarm="renewalAlarm" :info="info" @updateRenewalAlarm="updateRenewalAlarm" :collectYn="waterLevelYn"></stats-widget14>
            </div>

            <div class="col-12 col-lg-8 col-xxl">
              <group-widget
                  :humidity="humidity"
                  :humidityYn="humidityYn"
                  :co2="co2"
                  :co2Yn="co2Yn"
                  :ph="ph"
                  :phYn="phYn"
                  :ec="ec"
                  :ecYn="ecYn"
              ></group-widget>
            </div>
            <div class="col-12 col-lg-4 col-xxl-3">
              <stats-widget6
                  :led="led"
              ></stats-widget6>
              <stats-widget7
                  :fan="fan"
              ></stats-widget7>
            </div>
          </div>

          <div :class="dynamicClass()">
            <div class="col">
              <monitoring-chart-widget
                  widget-number="8"
                  v-bind:title="this.controller.temperature"
                  :categories="categories"
                  :chart-series="tempChartSeries"
                  :limitHigh="tempLimitHigh"
                  :limitLow="tempLimitLow"
                  :loading="loading"
                  :collectYn="tempYn"
              ></monitoring-chart-widget>
            </div>
            <div v-if="waterTempYn" class="col">
              <monitoring-chart-widget
                  widget-number="15"
                  v-bind:title="this.controller.waterTemperature"
                  :categories="categories"
                  :chart-series="waterTempChartSeries"
                  :limitHigh="waterTempLimitHigh"
                  :limitLow="waterTempLimitLow"
                  :loading="loading"
                  :collectYn="waterTempYn"
              ></monitoring-chart-widget>
            </div>

            <div class="col">
              <monitoring-chart-widget
                  widget-number="9"
                  v-bind:title="this.controller.humidity"
                  :categories="categories"
                  :chart-series="humidityChartSeries"
                  :limitHigh="humidityLimitHigh"
                  :limitLow="humidityLimitLow"
                  :loading="loading"
                  :collectYn="humidityYn"
              ></monitoring-chart-widget>
            </div>

            <div class="col">
              <monitoring-chart-widget
                  widget-number="10"
                  v-bind:title="this.controller.CO2"
                  :categories="categories"
                  :chart-series="co2ChartSeries"
                  :limitHigh="co2LimitHigh"
                  :limitLow="co2LimitLow"
                  :loading="loading"
                  :collectYn="co2Yn"
              ></monitoring-chart-widget>
            </div>

            <div class="col">
              <monitoring-chart-widget
                  widget-number="11"
                  v-bind:title="this.controller.EC"
                  :categories="categories"
                  :chart-series="ecChartSeries"
                  :limitHigh="ecLimitHigh"
                  :limitLow="ecLimitLow"
                  :loading="loading"
                  :collectYn="ecYn"
              ></monitoring-chart-widget>
            </div>

            <div class="col">
              <monitoring-chart-widget
                  widget-number="12"
                  v-bind:title="this.controller.pH"
                  :categories="categories"
                  :chart-series="phChartSeries"
                  :limitHigh="phLimitHigh"
                  :limitLow="phLimitLow"
                  :loading="loading"
                  :collectYn="phYn"
              ></monitoring-chart-widget>
            </div>
          </div>
        </div>
      </b-tabs>
    </div>
    <!--end::controller-tabs-->


    <!-- begin::measureModal -->
    <b-modal
      v-model="measureModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">{{connect.connectNm}} {{ i18n.input }}</span>
        </h3>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{ i18n.measuredEnvironment }}</span>
          </label>
          <div class="col-sm-4">
            <b-form-select v-model="envId" :options="envNmList" @input="setPresentValue()"></b-form-select>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{ i18n.controllerValue }}</span>
          </label>
          <div class="col-sm-4">
            <div class="input-group">
              <input v-model="presentValue" type="number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">{{measureType}}</span>
              </div>
            </div>
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{ i18n.measuredValue }}</span>
          </label>
          <div class="col-sm-4">
            <div class="input-group">
              <input v-model="measureValue" type="number" class="farm-form-control form-control">
              <div class="input-group-append">
                <span class="input-group-text">{{measureType}}</span>
              </div>
            </div>
          </div>
        </div>
      <!-- end::Body -->
        <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="secondary" size="lg" class="ml-3 float-right" @click="measureModal = false"><span class="">{{i18n.close}}</span></b-button>
            <b-button variant="primary" size="lg" class="ml-3 float-right" @click="addMeasure"><span class="">{{i18n.add}}</span></b-button>
          </div>
        <!--end::Footer-->
      </div>
      </div>
    </b-modal>
    <!-- end::measureModal -->


  </div>
  <!--end: Farm controller-->
</template>

<style>
.blur {
  filter: blur(8px) grayscale(100%);
  opacity: 0.5;
}
.overlay {
  position: absolute;
  z-index: 999;
}
</style>

<script>
import {mapGetters} from 'vuex';
import StatsWidget1 from '@/view/content/widgets/dashboard/Widget1.vue';
import StatsWidget13 from '@/view/content/widgets/dashboard/Widget13.vue';
import StatsWidget14 from '@/view/content/widgets/dashboard/Widget14.vue';
import StatsWidget6 from '@/view/content/widgets/dashboard/Widget6.vue';
import StatsWidget7 from '@/view/content/widgets/dashboard/Widget7.vue';
import GroupWidget from '@/view/content/widgets/dashboard/GroupWidget.vue';
import MonitoringChartWidget from '@/components/MonitoringChartWidget';
import {getItem, getItems, lengthCheck, timestampToDate, isSuccess, getTimeDifference} from '@/core/services/funcs';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT, ACT_SHOW_ERROR_ALERT} from '@/core/services/store/alert.module';
import {ACT_INSERT_STATUS_MEASURE, ACT_GET_STATUS_LOGS, ACT_GET_LAST_STATUS_LOG, ACT_GET_FARM_THRESHOLD, ACT_GET_RENEWAL_SET, ACT_UPDATE_RENEWAL_SET} from '@/core/services/variable';//ACT_GET_STATUS_LOGS_STATICS
import i18n from '@/core/plugins/vue-i18n.js';
import {SET_AUTH, PURGE_AUTH, REFRESH_TOKEN} from '@/core/services/store/auth.module';
export default {
  name: "farm-controller",
  components: {
    StatsWidget1,
    StatsWidget13,
    StatsWidget14,
    StatsWidget6,
    StatsWidget7,
    MonitoringChartWidget,
    GroupWidget
  },
  props:{
    controller: Object,
    connects: Array,
    effectValue: Number,
    fno: Number,
    info: Object
  },
  created(){
    this.setRenewalAlarm();
  },
  watch: {
    effectValue(){
      if(this.connects && this.connects.length) this.changeConnect(0);
      this.setRenewalAlarm();
    }
  },
  computed: {
    ...mapGetters(["isMobile", "loginStayYn"]),
  },
  destroyed() {
    this.clearStatusInterval();
  },
  data() {
    return {
      i18n: {
        input: i18n.t('dashboard.input'),
        inputMeasauredData: i18n.t('dashboard.inputMeasauredData'),
        measuredEnvironment: i18n.t('dashboard.measuredEnvironment'),
        controllerValue: i18n.t('dashboard.controllerValue'),
        measuredValue: i18n.t('dashboard.measuredValue'),
        add: i18n.t('dashboard.add'),
        close: i18n.t('dashboard.close'),
        renewing: i18n.t('dashboard.renewalAlarm.renewing'),
        editted: i18n.t('dashboard.renewalAlarm.editted'),
        error: i18n.t('dashboard.error')
      },
      idx: 0,
      systemId: '',
      thresholdData: [],
      tempLimitHigh: 0,
      tempLimitLow: 0,
      humidityLimitHigh: 0,
      humidityLimitLow: 0,
      co2LimitHigh: 0,
      co2LimitLow: 0,
      phLimitHigh: 0,
      phLimitLow: 0,
      ecLimitHigh: 0,
      ecLimitLow: 0,
      waterTempLimitHigh: 0,
      waterTempLimitLow: 0,

      waterTemperature: 0,
      waterLevel: -1,
      temperature: 0,
      humidity: 0,
      co2: 0,
      ph: 0,
      ec: 0,

      led: 0,
      fan: 0,

      connect: [],

      categories : [],
      tempChartSeries: [],
      waterTempChartSeries: [],
      humidityChartSeries: [],
      co2ChartSeries: [],
      phChartSeries: [],
      ecChartSeries: [],

      statusInterval: null,
      loading: false,

      isAlarmReleaseButtonClicked: false,

      measureModal : false,
      envId : null,
      measureValue : 0,
      presentValue: 0,
      envNmList: [
          {value: 'null', text: ``},
          {value: '1', text: `${i18n.t('dashboard.temperature')}`},
          {value: '2', text: `${i18n.t('dashboard.humidity')}`},
          {value: '3', text: `${i18n.t('dashboard.waterTemperature')}`},
          {value: '4', text: 'CO2'},
          {value: '5', text: 'EC'},
          {value: '6', text: 'pH'},
      ],
      measureType: '',
      renewalAlarmList: {},
      renewalAlarm: true,
      tempYn: true,
      humidityYn: true,
      waterTempYn: true,
      co2Yn: true,
      ecYn: true,
      phYn: true,
      waterLevelYn: true,
      timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  },
  methods: {
    setRenewalAlarm(){
      this.clearAlarm()
      Promise.all([this.$store.dispatch(ACT_GET_RENEWAL_SET)]).then(([resp]) => {
        if(lengthCheck(resp)){
          const item = getItems(resp);
          item.forEach(connects => {
            this.renewalAlarmList[connects.systemId] = connects.alarm === 'on';
          })
        }
      }).catch(e => {
        console.error(e);
      });      
    },    
    updateRenewalAlarm(newRenewalAlarm) {
      this.renewalAlarm = newRenewalAlarm;
      this.renewalAlarmList[this.systemId] = this.renewalAlarm
      const onOff = this.renewalAlarm? 'on' : 'off'
      
      this.$store.dispatch(ACT_UPDATE_RENEWAL_SET, {fno: this.fno, systemId: this.systemId, params: onOff}).then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.editted, color: 'success'});
          }
        }).catch(e => {
          console.error(e);
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('dashboard.error')});
        });
    },
    addMeasure(){
      if(this.connect.systemId == 0 || this.connect.systemId == null){
        this.$store.dispatch(ACT_ADD_ALERT, {message: `${i18n.t('dashboard.noSystemID')}`, color: 'danger'});
        return
      }
      if(this.envId == null){
        this.$store.dispatch(ACT_SHOW_ERROR_ALERT, `${i18n.t('dashboard.plzInputEnvironment')}`)
        return
      }else if(this.measureValue == null){
        this.$store.dispatch(ACT_SHOW_ERROR_ALERT, `${i18n.t('dashboard.plzInputMeasuredValue')}`)
        return
      }

      let systemVal = 0
      let measureVal = 0
      switch(this.envId){
        case '1':
         systemVal = this.presentValue*100
         measureVal = this.measureValue*100
          break
        case '2':
         systemVal = this.presentValue*100
         measureVal = this.measureValue*100
          break
        case '3':
         systemVal = this.presentValue*100
         measureVal = this.measureValue*100
          break
        case '4':
         systemVal = this.presentValue*1
         measureVal = this.measureValue*1
          break
        case '5':
         systemVal = this.presentValue*1000
         measureVal = this.measureValue*1000
          break
        case '6':
         systemVal = this.presentValue*100
         measureVal = this.measureValue*100
          break
      }
      let params = {
        systemId: this.connect.systemId,
        type : this.envNmList.filter(env => env.value == this.envId).map(o => {
          if(o.text === 'Temperature'){
            o.text = '온도'
          } else if(o.text === 'Humidity'){
            o.text = '습도'
          } else if(o.text === 'Water Temperature'){
            o.text = '수온'
          }
          return o
        })[0].text,
        measure : measureVal.toFixed(0),
        systemValue : systemVal.toFixed(0)
      }


      if(systemVal <=0){
        const yesCallback = () =>{
          this.$store.dispatch(ACT_INSERT_STATUS_MEASURE, params).then((resp) =>{
            if(getItem(resp).number == 406){
              const yesCallback = () => {
                params.type = params.type + '_DUP'
                this.$store.dispatch(ACT_INSERT_STATUS_MEASURE, params).then(()=>{
                  this.$store.dispatch(ACT_ADD_ALERT, {message: `${i18n.t('dashboard.successMeasuredValue')}`, color: 'success'});
                  this.measureModal = false
                })
              }
              this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
                info: {title: `${i18n.t('dashboard.overwriteMeasuredValue')}`},
                callback: yesCallback,
              });
            }else{
                this.$store.dispatch(ACT_ADD_ALERT, {message: `${i18n.t('dashboard.successMeasuredValue')}`, color: 'success'});
              this.measureModal = false
            }
          })
        }
        this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
            info: {title: `${i18n.t('dashboard.noControllerValue')}`},
            callback: yesCallback
          });
      }else{
        this.$store.dispatch(ACT_INSERT_STATUS_MEASURE, params).then((resp) =>{
          if(getItem(resp).number == 406){
            const yesCallback = () => {
              params.type = params.type + '_DUP'
              this.$store.dispatch(ACT_INSERT_STATUS_MEASURE, params).then(()=>{
                this.$store.dispatch(ACT_ADD_ALERT, {message: `${i18n.t('dashboard.successMeasuredValue')}`, color: 'success'});
                this.measureModal = false
              })
            }
            this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
              info: {title: `${i18n.t('dashboard.overwriteMeasuredValue')}`},
              callback: yesCallback,
            });
          }else{
              this.$store.dispatch(ACT_ADD_ALERT, {message: `${i18n.t('dashboard.successMeasuredValue')}`, color: 'success'});
            this.measureModal = false
          }
        })
      }

    },
    setPresentValue(){
      switch(this.envId){
        case '1':
          this.presentValue = this.temperature == 0 ? null : (this.temperature).toFixed(2)
          this.measureType = '°C'
          break
        case '2':
          this.presentValue = this.humidity == 0 ? null :  (this.humidity).toFixed(2)
          this.measureType = '%'
          break
        case '3':
          this.presentValue = this.waterTemperature == 0 ?  null : (this.waterTemperature).toFixed(2)
          this.measureType = '°C'
          break
        case '4':
          this.presentValue = this.co2 == 0 ? null :  this.co2/1
          this.measureType = 'ppm'
          break
        case '5':
          this.presentValue = this.ec == 0 ? null :  (this.ec/10).toFixed(3)
          this.measureType = 'dS/m'
          break
        case '6':
          this.presentValue = this.ph == 0 ? null :  (this.ph/10).toFixed(2)
          this.measureType = 'pH'
          break
      }
    },
    showMeasureModal(){
      this.measureModal = true
      this.envId = null
      this.measureValue = null
      this.presentValue = null
      this.measureType = ''
    },
    setThreshold(){
      let growType = this.connects[this.idx].growType
      this.tempLimitHigh = this.thresholdData.tempDayLimithigh
      this.tempLimitLow = this.thresholdData.tempDayLimitlow
      this.humidityLimitHigh = this.thresholdData.humidityDayLimithigh
      this.humidityLimitLow = this.thresholdData.humidityDayLimitlow
      this.co2LimitHigh = this.thresholdData.co2DayLimithigh
      this.co2LimitLow = this.thresholdData.co2DayLimitlow
      this.phLimitHigh = this.thresholdData.phLimithigh
      this.phLimitLow = this.thresholdData.phLimitlow
      this.waterTempLimitHigh = this.thresholdData.waterTempLimithigh
      this.waterTempLimitLow = this.thresholdData.waterTempLimitlow

      if(growType === '육묘'){
        this.ecLimitHigh = this.thresholdData.seedEcLimithigh
        this.ecLimitLow = this.thresholdData.seedEcLimitlow
      }else if(growType === '이식'){
        this.ecLimitHigh = this.thresholdData.transplantEcLimithigh
        this.ecLimitLow = this.thresholdData.transplantEcLimitlow
      }else{
        this.ecLimitHigh = this.thresholdData.ecLimithigh
        this.ecLimitLow = this.thresholdData.ecLimitlow
      }
    },
    getStatusLogs(){
      let d = new Date()
      const yesterdayYmd = timestampToDate(new Date(d.setDate(d.getDate() -1)).getTime(),'yyyyMMdd hh:mm')
      const todayYmd = timestampToDate(new Date().getTime(),'yyyyMMdd hh:mm')
      this.clearStatusLog();
      if(this.systemId){
        this.loading = true;
        Promise.all([
          // this.$store.dispatch(ACT_GET_STATUS_LOGS_STATICS, {systemId: this.systemId, dateCd: 'h', pageNo: 1, pageSize: 1000}),
          this.$store.dispatch(ACT_GET_FARM_THRESHOLD, this.fno),
          this.$store.dispatch(ACT_GET_LAST_STATUS_LOG, this.systemId),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: yesterdayYmd, endDate: todayYmd, field: 'temp_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: yesterdayYmd, endDate: todayYmd, field: 'water_temp_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: yesterdayYmd, endDate: todayYmd, field: 'humidity_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: yesterdayYmd, endDate: todayYmd, field: 'co2_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: yesterdayYmd, endDate: todayYmd, field: 'ec_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: yesterdayYmd, endDate: todayYmd, field: 'ph_value'}),
        ]).then(([/*logs,*/thresResp, lastLog, tempLogs, waterTempLogs, humidityLogs, co2Logs, ecLogs, phLogs]) => {
          if(!(thresResp.items.length == 0)){
            this.thresholdData = thresResp.items[0]
          }else{
            this.$store.dispatch(ACT_GET_FARM_THRESHOLD, 0).then(baselineResp =>{
              this.thresholdData = baselineResp.items[0]
            })
            this.setThreshold()
          }

          this.setThreshold()

          if(lengthCheck(lastLog)){
            const lateStatusLog = getItem(lastLog)
            this.temperature = lateStatusLog.tempValue/100 < 100 ? lateStatusLog.tempValue/100 : 0;
            this.humidity= lateStatusLog.humidityValue/100 <= 100 ? lateStatusLog.humidityValue/100 : 0
            this.waterTemperature = lateStatusLog.waterTempValue == 'N/A' || lateStatusLog.waterLevel == null  ? 0 : lateStatusLog.waterTempValue/100;
            this.co2= parseInt(lateStatusLog.co2Value/1 < 3000 ? lateStatusLog.co2Value/1 : 0)
            this.ph= lateStatusLog.phValue/100 <= 14 ? lateStatusLog.phValue/10 : 0
            this.ec= lateStatusLog.ecValue/1000 <= 5 ? lateStatusLog.ecValue/100 : 0
            this.fan = lateStatusLog.fan == 'N/A' ? -1 : parseInt(lateStatusLog.fan) == 1 ? 1 : 0;
            this.led = lateStatusLog.led == 'N/A' ? -1 : parseInt(lateStatusLog.led) == 1 ? 1 : 0;
            this.waterLevel = lateStatusLog.waterLevel == 'N/A' || lateStatusLog.waterLevel == null ? -1 : Number(lateStatusLog.waterLevel)
            this.renewalAlarm = this.renewalAlarmList[this.systemId]
          }

          getItems(tempLogs).forEach(log =>{
            const key = timestampToDate(getTimeDifference(log.time, this.timeZone)*1000, 'yyyy/MM/dd hh:mm:00')
            this.tempChartSeries.push([key, log.value/100 < 100 ? log.value/100 : 0])
          })
          getItems(waterTempLogs).forEach(log =>{
            const key = timestampToDate(getTimeDifference(log.time, this.timeZone)*1000, 'yyyy/MM/dd hh:mm:00')
            this.waterTempChartSeries.push([key, log.value/100 < 100 ? log.value/100 : 0])
          })
          getItems(humidityLogs).forEach(log =>{
            const key = timestampToDate(getTimeDifference(log.time, this.timeZone)*1000, 'yyyy/MM/dd hh:mm:00')
            this.humidityChartSeries.push([key, log.value/100 <= 100 ? log.value/100 : 0])
          })
          getItems(co2Logs).forEach(log =>{
            const key = timestampToDate(getTimeDifference(log.time, this.timeZone)*1000, 'yyyy/MM/dd hh:mm:00')
            this.co2ChartSeries.push([key, parseInt(log.value < 3000 ? log.value : 0)])
          })
          getItems(ecLogs).forEach(log =>{
            const key = timestampToDate(getTimeDifference(log.time, this.timeZone)*1000, 'yyyy/MM/dd hh:mm:00')
            this.ecChartSeries.push([key, log.value/1000 < 5 ? log.value/1000 : 0])
          })
          getItems(phLogs).forEach(log =>{
            const key = timestampToDate(getTimeDifference(log.time, this.timeZone)*1000, 'yyyy/MM/dd hh:mm:00')
            this.phChartSeries.push([key, log.value/100 < 14 ? log.value/100 : 0])
          })

          this.categories = []
          this.categories.push(d.getTime())

          this.setPresentValue()
          this.loading = false;
        }).catch(e => {
          if(this.loginStayYn === 'Y' ) {
            this.refreshToken()
          } else {
            console.error(e);

            this.clearStatusLog();
            this.clearStatusInterval();
            this.loading = false;
          }
        })
      }else{
        this.clearStatusLog();
      }
    },
    refreshToken() {
      this.$store.dispatch(REFRESH_TOKEN)
      .then(resp => {
        if(isSuccess(resp)){
          this.$store.commit(SET_AUTH, {uuid: resp.uuid, mno: resp.mno, authToken: resp.authToken, refreshToken: resp.refreshToken, loginStayYn: this.loginStayYn});
          // window.location.href = window.location.pathname;
          this.setStatusInterval()
        } else {
          this.$store.commit(PURGE_AUTH);
          this.$router.push({ name: "signin" });
        }
      }).catch(e => {
        console.error(e);
        this.$store.commit(PURGE_AUTH);
        this.$router.push({ name: "signin" });
      })
    },
    clearAlarm(){
      this.connects.forEach(connect => {
        this.renewalAlarmList[connect.systemId] = true;
      })
    },
    clearLimit(){
      this.tempLimitHigh = 0
      this.humidityLimitHigh = 0
      this.co2LimitHigh = 0
      this.phLimitHigh = 0
      this.ecLimitHigh = 0
      this.waterTempLimitHigh = 0
      this.tempLimitLow = 0
      this.humidityLimitLow = 0
      this.co2LimitLow = 0
      this.phLimitLow = 0
      this.ecLimitLow = 0
      this.waterTempLimitLow = 0
    },
    clearSeries(){
      this.temperature = 0
      this.waterTemperature = 0
      this.waterLevel = -1
      this.humidity= 0
      this.co2 = 0
      this.ph= 0
      this.ec= 0

      this.fan = -1
      this.led = -1

      this.categories = []
      this.tempChartSeries = []
      this.waterTempChartSeries = []
      this.humidityChartSeries = []
      this.co2ChartSeries = []
      this.phChartSeries = []
      this.ecChartSeries = []

      this.tempYn = true
      this.humidityYn = true
      this.waterTempYn = true
      this.co2Yn = true
      this.ecYn = true
      this.phYn = true
      this.waterLevelYn = true
    },
    clearStatusLog(){
      this.clearLimit();
      this.clearSeries();
    },
    setStatusInterval(){
      this.clearStatusInterval();
      this.getStatusLogs();
      this.getYn(this.connect)

      this.statusInterval = setInterval(() => {
        this.getStatusLogs()
        this.getYn(this.connect)
      }, 60000);
    },
    clearStatusInterval(){
      if(this.statusInterval) {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
      }
    },
    changeConnect(i){
      this.idx = i;
      if(this.connects[i] && this.connects[i].systemId) {
        this.connect = this.connects[i];
        this.systemId = this.connects[i].systemId;

        this.setStatusInterval();
        this.getYn(this.connect)
      }else{
        this.systemId = '';
        this.clearStatusInterval();
        this.clearStatusLog();
      }

    },
    activateTab(newTabIndex, prevTabIndex, bvEvent) {
      if (this.isAlarmReleaseButtonClicked) {
        this.isAlarmReleaseButtonClicked = false
        bvEvent.preventDefault()
        return;
      }
      this.changeConnect(newTabIndex)
    },
    getYn(connect){
      this.tempYn = connect.tempYn ==='Y' ? true : false;
      this.humidityYn = connect.humidityYn ==='Y' ? true : false;
      this.waterTempYn = connect.waterTempYn === 'Y' ? true : false;
      this.co2Yn = connect.co2Yn ==='Y' ? true : false;
      this.ecYn = connect.ecYn ==='Y' ? true : false;
      this.phYn = connect.phYn ==='Y' ? true : false;
      this.waterLevelYn = connect.waterLevelYn ==='Y' ? true : false;
    },
    dynamicClass() {
      if (this.waterTempYn) {
        return 'row row-cols-1 row-cols-lg-2 row-cols-xl-6';
      } else {
        return 'row row-cols-1 row-cols-lg-2 row-cols-xl-5';
      }
    }
  },
};
</script>
